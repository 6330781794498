import React from 'react';

import { H, Page } from '../../../../ui';

const Home = () => (
  <Page>
    <H l={1}>Eon Codex</H>
  </Page>
);

export default Home;
